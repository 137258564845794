import React from "react";
import { useTimeout } from "../hooks/react-use";

type Props = {
  /** Delay in ms until component children are mounted */
  delay: number;
  children: React.ReactNode;
};

/**
 * DelayedMount is a component that delays mounting of its children by a given delay.
 */
export const DelayedMount = ({ delay, children }: Props) => {
  const [isDisplayed] = useTimeout(delay || 0);

  return isDisplayed() ? <>{children}</> : null;
};
